// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$white: white;
$grey: #909090;

body {
  font-family: 'Lato';
}

.fa-btn {
  margin-right: 6px;
}

.fwhite {
  color: $white;
}

.fgrey {
  color: $grey;
}

.none {
  display: none;
}

.cursor {
  cursor: pointer;
}

.fsmall {
  font-size: .8em;
}

.tab-content > .tab-pane {
  margin-top: 1em !important;
}

.ml15 {
  margin-left: 1em;
}

.mr10 {
  margin-right: 1em;
}

.mb10 {
  margin-bottom: 1em;
}

.mt10 {
  margin-top: 10px;
}

.pt10 {
  padding-top: 10px;
}

.pt20 {
  padding-top: 20px;
}

.pt30 {
  padding-top: 30px;
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
  color: #222;
}

.h140 {
  height: 140px;
}

.oh {
  overflow: hidden;
}

.navbar-default {
  background-color: #f2eccc;
}

.panel-default > .panel-heading {
  background-color: #f2eccc;
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color: #f2eccc;
}

.gridtable > tbody > tr > td > input {
  width:100px;
}

.panel-primary {
  border-color: #e4dcae;
}

.panel-primary > .panel-heading {
  background-color: #f2eccc;
  border-color: #f2eccc;
  color: #51460a;
}

.panel-success {
  border-color: #b7b088;
}

.panel-success > .panel-heading {
  background-color: #d5c591;
  border-color: #d5c591;
  color: #51460a;
}

.panel-info {
  border-color: #8c7f36;
}

.panel-info > .panel-heading {
  background-color: #bcac4f;
  border-color: #bcac4f;
  color: #ffffff;
}

.table-striped .table-text {
  vertical-align: middle;
}

.checkbox {
  padding-left: 20px;
}

.checkbox label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
}

.checkbox label::before {
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 3px;
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: border 0.15s ease-in-out 0s, color 0.15s ease-in-out 0s;
  width: 17px;
}

.checkbox label::after {
  color: #555555;
  display: inline-block;
  font-size: 11px;
  height: 16px;
  left: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  position: absolute;
  top: 0;
  width: 16px;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline: thin dotted;
  outline-offset: -2px;
}

.checkbox input[type="checkbox"]:checked + label::after {
  content: "";
  font-family: "FontAwesome";
}

.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #428bca;
  border-color: #428bca;
}

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f;
}

.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.userEdit p {
  padding: 6px 0 0 0;
  margin: 0;
}

.navbar-header {
  border-right: solid 1px #bcac4f;
}

.keyImage {
  border: solid 2px gold;
  box-shadow: 2px 2px 2px #000;
}

.makeMain {
  position: absolute;
  top: -15px;
  left: 15px;
  color: darkgoldenrod;
}

.updated {
  background-color: lightgoldenrodyellow;
  border-color: lightseagreen;
}

.fred {
  color: #FF8080;}

.removeImage { float:right;}